@font-face {
  font-family: "SFUIDisplay";
  src: url("fonts/SFUIDisplay-Medium.ttf") format("truetype");
  font-weight: 300; /* display header */
}

@font-face {
  font-family: "SFUIDisplay";
  src: url("fonts/SFUIDisplay-Light.ttf") format("truetype");
  font-weight: 200; /* display text */
}

@font-face {
font-family: "SwedenSans";
src: url("fonts/SwedenSansBook.otf") format("truetype");
font-weight: 500; /* display scroller */
}

@font-face {
  font-family: "SFUIText";
  src: url("fonts/SFUIText-Regular.ttf") format("truetype");
  font-weight: 300; /* text header */
}

@font-face {
  font-family: "SFUIText";
  src: url("fonts/SFUIText-Light.ttf") format("truetype");
  font-weight: 200; /* text text */
}


body {
  background-color: lightgrey;
  margin: 0;
  font-size: 15px;
  font-family: SFUIText, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

.spacer{

    position: absolute;
    height: 100%;
    width: 100%;
    display: block;

}

.App{

  margin-top: 100vh;
  margin-bottom: 100vh;

}

a {
  color: inherit;      /* Let the links inherit the color of their parent */
  text-decoration: none;   /* Removes the underline */
}

a:hover {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.site__header {
  display: grid;
  height: 50px;
  grid-template-columns: 1fr 0.5fr 1fr;
  justify-content: flex-start;
  top: 10px;
  padding: 10px 15px 10px 15px;
  right: 10px;
  left: 10px;
  color: black;
  position: fixed;
  z-index: 100;
  backdrop-filter: blur(20px) saturate(100%);
  -webkit-backdrop-filter: blur(20px) saturate(100%);
  transition: 0.3s ease;
  letter-spacing: 0.04em;
  
}

.site__header.expanded{
  
  background-color: rgba(255,255,255,.2);
  backdrop-filter: blur(300px) saturate(400%);
  height: calc(100vh - 40px);
  transition: 0.6s ease;
 
}

.site__header__text{

  position: absolute;
  align-self: center;
  grid-column: 1/2;
  min-width: 270px;
  transition: 0.6s ease;

}

.site__header__contact{

  position: absolute;
  align-self: center;
  font-size: 0.8rem;
  grid-column: 1/2;
  min-width: 350px;
  visibility: hidden;
  margin-top: 0px;
  opacity: 0;
  text-transform: uppercase;
  font-family: SFUIDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  

}

.colophon {
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  right: 0px;
  bottom: 10px;
  position: absolute;
  grid-column: 3 / 3;
  display: block;
    font-size: 0.7rem;
    font-weight: 200;
    text-align: right;
    font-family: SFUIDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;

}

.colophon.expanded {
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition-property: visibility, opacity;
  transition-duration:0s, 0.4s, 0.8s;
  transition-delay:0s, 0.2s, 0.4s;
  transition-timing-function:ease;

}


.site__header__contact.expanded{

  position: absolute;
  align-self: center;
  grid-column: 1/2;
  min-width: 350px;
  margin-top: 15px;
  visibility: visible;
  margin-top: 15px;
  opacity: 1;
  transition-property: visibility, opacity, margin-top;
  transition-duration:0s, 0.4s, 0.8s;
  transition-delay:0s, 0.2s, 0.4s;
  transition-timing-function:ease;

}

.bar__burger{

  grid-column: 2/3;
  justify-self: center;
  margin-top: 37px;
  height: 28px;

}

.site__header__longtext{

  grid-column: 3/3;
  justify-self: center;
  align-self: center;
  font-size: 1em;
  font-weight: 200;
  max-width: 600px;
  line-height: 1.25em;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  

}

.site__header__longtext.expanded{

  opacity: 1;
  /* Make the text visible when it's opaque */
  visibility: visible;
  transition: opacity 0.6s ease;
  transition-delay:0.4s;

}

.statement{

  font-style: italic;


}

.bar1, .bar2, .bar3  {

  width: 35px;
  height: 2px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.8s ease;

}

.bar1.expanded, .bar2.expanded, .bar3.expanded  {

  margin: 8px 0;
  transition: 0.8s ease;

}


.site__title {
  display: block;
  font-size: 0.9em;
  font-weight: 300;
  text-align: left;
  font-family: SFUIDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  text-transform: uppercase;
}

.site__subtitle {
  display: block;
  font-size: 0.9em;
  font-weight: 200;
  text-align: left;
  font-family: SFUIDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  text-transform: uppercase;

}

.grid{
  
  background-color: black;
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  align-content:  start;
  justify-content: left;


}



.grid__item{

  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 125px;
  height: 25vh;
  min-height: 275px;
  position: relative;
  flex-grow: 1;
  justify-content: left;
  flex-shrink: 1;
  

}

.grid__item:last-child {
  max-width: 600px;
}

.lazy-load-image-background{
  display: flex;
  width: 100%;
  background-size: cover !important;
}

.grid__item img:hover, .grid__item video:hover {
  cursor: pointer;
}

.modal{
  cursor: pointer;
}

.bar__burger{
  cursor: pointer;

}

.fragment__link{
  cursor: pointer;

}

.grid__item img, .grid__item video { 

  object-fit: cover;
  height: 25vh;
  min-height: 275px;
  flex-grow: 1;
  width: 100%;


  }

  .grid__item video { 

    object-fit: cover;
    background-color: black;
    width: 450px;
    
  
    }

    .portrait { 

      width: 150px !important;
    
      }


  .last {

    flex-grow: 999999; /* large number to ensure it takes up any remaining space */
    width: 100%;
    height: 0px;


  }



  .titlecard__wrapper{

    flex-basis: 25%;
    min-width: 375px;
    flex-grow: 1;
    display: flex;
    position: relative;
    border-left-style: solid;
    border-width: 0px 0px 0px 0px;

  }

  /*

  .titlecard__wrapper:hover .grid__item__titlecard {
    
    opacity: 1;
    
    transition: opacity 0.3s ease;
  }



  .titlecard__wrapper .titlecard__scroller{

    
    font-family: SwedenSans, SFUIDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    transform: translateY();
    font-weight: 500;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    text-transform: uppercase;
    opacity: 1;
    transition: opacity 0.3s ease;

  }

  
  .titlecard__wrapper:hover .titlecard__scroller {
    
    opacity: 0;
    transition: opacity 0.3s ease;
  }


  .scroller {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;

}


.scroller > div {
    display: flex;
    animation: scrollText 30s infinite linear;
 
}

.scroller h1 {

  margin-right: 40px;
  font-size: 40px;
  color: black;
  /*letter-spacing: 0.12em;
  transform: translateY(5px)
  /* text-transform: uppercase;
}

/*
@keyframes scrollText {
    0% {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
   }
    100% {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
   }
}

*/

  .grid__item__titlecard{

    /* justify-content: space-between; */
    flex-direction: row;
    /* flex-wrap: nowrap; */
    /* opacity: 0; */
    background-color: lightgray;
    display: flex;
    align-content: baseline;
    z-index: 2;
    transition: opacity 0.3s ease;
        
  
  }


  .fragment__title{

    display: block;
    font-size: 1.2em;
    font-weight: 300;
    margin: 0;
    margin: 10px;
    text-transform: uppercase;
    max-width: 600px;
    flex-basis: 100%;
  
  }

  .fragment__blurb{
      
    display: block;
    font-size: 0.8em;
    margin: 0 10px 15px 10px;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    max-width: 350px;
    font-weight: 200;
    flex-basis: 100%;
    
  }

  .fragment__provedence{

    position: absolute;
    bottom: 10px;
    left: 10px;
    border-style: solid;
    border-radius: 15px;
    border-width: 1.5px;
    padding: 4px 8px 4px 8px;
    font-weight: 200;
 
  }

  .fragment__context{

    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0px 10px 10px 10px;
    font-size: 0.8em;
    font-weight: 300;

    
}

.fragment__context ul{

  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-end: 0px;
  margin-block: 0px;
  
} 



.fragment__context__left{
  white-space: nowrap;
  text-align: left;

}

.fragment__context__right{

  text-align: right;
  white-space: nowrap;

}

  
  .fragment__link__container{

    
    display: inline-flex;
    text-align: center;
 
    /* width: 40px; */
    color: #000000;
    /* background-color: grey; */
    text-wrap: nowrap;
    flex-basis: 100%;
    align-items: flex-start; /* Add this line */

  }

  .fragment__link{

    color: #000000;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0px 5px 10px 5px;
    padding: 6px 10px 6px 10px;
    font-size: 0.8em;
    border-width: 1px;
    border-radius: 20px;
    border-style: solid;
    border-color: #000000;

  }

  .fragment__link:hover {
    color: #d3d3d3;
    background-color: #000000;
  }

  .media__meta{

   width: 90%;
   color: blanchedalmond;
   z-index: 1;
   font-size: 1.25em;
   font-weight: 200;
   

  }

  .media__meta__title{

      position: absolute;
      top: 10px;
      left: 10px;
      color: black;
      z-index: 1;
      font-size: 1.2em;
      font-weight: 300;
      display: absolute;
      text-transform: uppercase;
      max-width: 800px;
      text-align: left;
      
    
      
  
  }

  .media__meta__blurb{

    
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: black;
    z-index: 1;
    font-size: 1em;
    font-weight: 200;
    display: absolute;
    max-width: 600px;
    text-align: left;
  
    

}

  .viewing {

    height: 450px;

  }

  .reflow {
    transform: translateZ(0);
}




/* Grid.css */

.modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  bottom: 10px;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 100;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  background-color: rgba(255,255,255,.2);

}


.modal img,
.modal video {
  object-fit: contain;
  width: 95%;
  max-height: 80%;
 


}



@media only screen and (max-width: 650px) {


  .titlecard__wrapper{

    min-width: 280px;
    flex-basis: 25%;

  }

  .site__title {
    font-size: 0.8em;
  }

  .grid__item video { 


    width: 100%;
    
  
    }

  .site__subtitle {
    font-size: 0.8em;
  }

  .site__header{
    height: 40px;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .site__header.expanded {
    height: calc(100vh - 30px);
    transition: 0.6s ease;
    }

    .site__header__contact {
        font-size: 0.7rem;
      }

      .colophon {
        font-size: 0.6rem;
      }

      .site__header__text {
        top: 15px;
        transition: 0.6s ease;

      }

      .site__header__text.expanded {
        top: 50px;
        transition: 0.6s ease;
    }

    .site__header__longtext {
      font-size: 0.7em;
      position: absolute;
      top: 160px;
      grid-column: 1 / 4;
      justify-self: start;
      align-self: auto;
 
      font-weight: 200;
      max-width: 600px;
      line-height: 1.25em;
      text-align: left;
      opacity: 0;
      visibility: hidden;
  }

  .bar__burger{
    grid-column: 3 / 3;
    justify-self: right;
    margin-top: 11px;
    height: 28px;
  }

  .fragment__title{
    font-size: 1em;
  }

  .fragment__blurb{
    font-size: 0.7em;
  }

  .fragment__context{
    font-size: 0.7em;
  }

  .fragment__link{
    font-size: 0.7em;
  }

  .media__meta__title{
    font-size: 1em;
  }

  .media__meta__blurb{
    font-size: 0.8em;
  }

  .modal{

    right: 5px;
    bottom: 5px;
    top: 5px;
    left: 5px;

  }


    .modal img, .modal video {
      width: 98%;
      }

}